import React from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

const AboutSection = () => {
  return (
    <div className='about-section'>
        <div className='section-content container p-0'>
            <div className='section-item section-text-con item-1 w-md-66'>
                <h4 className='section-title text-border-right'>About THC Club</h4>
                <h3 className='text-theme-prmary'>THC Club is Houston's #1 Recreational Cannabis Shop!</h3>
                <h5 className='text-theme-grey'>
                    THC Club prides itself on bringing high-quality cannabis products to the Houston market. <br/><br/>
                    From seed-to-sale, our cannabis products are of the finest quality and come straight from the farms to our shelves, with no middlemen in between.
                </h5>
                <Link to='about-us' className="btn-readmore btn link-btn btn-secondary hover-btn rounded-pill">
                    <span>Read More</span>
                </Link>
            </div>
            {/* <div className='section-item section-img item-2'>
                <img src='https://thcclubhouston.com/wp-content/uploads/2022/06/free-use-of-hookah-flyer-1-724x1024.png' className="img-fluid" alt="About THC"/>
            </div> */}
            {/* <div className='section-item section-img bg-theme-secondary item-3'>
                <img src='https://thcclubhouston.com/wp-content/uploads/2022/06/free-use-of-hookah-flyer-724x1024.png' className="img-fluid" alt="About THC"/>
            </div> */}
            <div className='section-item bg-theme-primary rounded-4 section-opening-hours item-5  w-md-33'>
                <h4 className='text-white text-border-right'>Opening hours</h4>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Monday:</span>
                    <span className='text-white'>10:00 AM - 10:00 PM</span>
                </div>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Tuesday:</span>
                    <span className='text-white'>10:00 AM - 10:00 PM</span>
                </div>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Wednesday:</span>
                    <span className='text-white'>10:00 AM - 10:00 PM</span>
                </div>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Thursday:</span>
                    <span className='text-white'>10:00 AM - 11:00 PM</span>
                </div>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Friday:</span>
                    <span className='text-white'>10:00 AM - 11:00 PM</span>
                </div>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Saturday:</span>
                    <span className='text-white'>10:00 AM - 11:00 PM</span>
                </div>
                <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Sunday:</span>
                    <span className='text-white text-uppercase'>10:00 AM - 11:00 PM</span>
                </div>
                {/* <div className='opening-hours-item d-flex align-items-center justify-content-between'>
                    <span className='text-theme-secondary'>Online service:</span>
                    <span className='text-white text-uppercase'>Open 24 hours</span>
                </div> */}
            </div>
            
            <div className='section-item section-img-text rounded-4 item-4 w-100'>
                <h3 className='text-white'>Houston's only <br/> seed-to-sale  <br/><span className='text-theme-secondary'>cannabis shop</span></h3>
            </div>
            {/* <div className='section-item section-img item-6'>
                <h4 className='section-title text-border-right'>GROWING</h4>
                <h3>Best Cannabis <br/> Dispensary in <br/>Houston</h3>
            </div>
            <div className='section-item section-img-text rounded-4 item-7'>
                <div className='item-content'>
                    <h4 className='text-theme-secondary m-0'>NEW STORE ALERT</h4>
                    <h2 className='text-white m-0'>-10%</h2>
                    <h5 className='text-white mt-2'>
                        OFF PICK UP ORDERS
                    </h5>
                    <h6 className='text-white mt-2'>
                        THC CLUB CYPRESS<br/>18060 FM-529 cypress, TX 77433
                    </h6>
                </div>
            </div> */}
        </div>
    </div>
  )
}

export default AboutSection